import ItAdmin from "./core";
import Toast from "./core/components/Toast";

function App() {
  return (
    <>
      <ItAdmin />
      <Toast/>
    </>
  );
}

export default App;
